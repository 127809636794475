import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { GrPlan } from "react-icons/gr";
import { FaUserCircle } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import { TbBrandAirtable } from "react-icons/tb";
import { CiViewList } from "react-icons/ci";
import { MdOutlineContactPhone } from "react-icons/md";

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true}); 
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="/plans"><img src={require('../../assets/images/logos.png')} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini" href="/plans"><img src={require('../../assets/images/sullamlogo.png')} alt="logo" /></a>
        </div>
        <ul className="nav">
       
          {/* <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Navigation</Trans></span>
          </li> */}
   
          
          <li className={ this.isPathActive('/tables') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
   
            <Collapse in={true}>
              <div>
                 {/* <ul className="nav flex-column sub-menu"> 
                  <li className="nav-item"> <Link className={ this.isPathActive('/plan') ? 'nav-link active' : 'nav-link' } to="/plans"><Trans><GrPlan className='mr-2'/>Planet</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/users') ? 'nav-link active' : 'nav-link' } to="/users"><Trans><FaUserCircle className='mr-2'/>Users</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/faq') ? 'nav-link active' : 'nav-link' } to="/faq"><Trans><FaQuestionCircle className='mr-2'/> Faq  </Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/cmslist') ? 'nav-link active' : 'nav-link' } to="/cmslist"><Trans><CiViewList className='mr-2'/>Cms</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/roadmaplist') ? 'nav-link active' : 'nav-link' } to="/roadmaplist"><Trans><GoProjectRoadmap className='mr-2'/>Roadmap</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/contactlist"><Trans><MdOutlineContactPhone className='mr-2' />Contact us </Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/Category"><Trans><MdOutlineContactPhone className='mr-2' />Category</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/poollist"><Trans><MdOutlineContactPhone className='mr-2' />Hard Stake Pool</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/gameassets"><Trans><MdOutlineContactPhone className='mr-2' />Game List</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/collectionlist"><Trans><MdOutlineContactPhone className='mr-2' />Collection</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/collectiontypelist"><Trans><MdOutlineContactPhone className='mr-2' />Collection Type</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/tokenlist"><Trans><MdOutlineContactPhone className='mr-2' />Tokens</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/shiplist"><Trans><MdOutlineContactPhone className='mr-2' />ships</Trans></Link></li>
                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/crewlist"><Trans><MdOutlineContactPhone className='mr-2' />crew</Trans></Link></li>

                     <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/missionrewardlist"><Trans><MdOutlineContactPhone className='mr-2' />Mission reward</Trans></Link></li>

                </ul>  */}
              <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Promotion Site</Trans></span>
          </li>
          <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/bloglist"><Trans><MdOutlineContactPhone className='mr-2' />Blog List</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/newslist"><Trans><MdOutlineContactPhone className='mr-2' />News List</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/sociallist"><Trans><MdOutlineContactPhone className='mr-2' />Social List</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/partners"><Trans><MdOutlineContactPhone className='mr-2' />partners</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/promobuildlist"><Trans><MdOutlineContactPhone className='mr-2' />Promo Build</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/uploadpdf"><Trans><MdOutlineContactPhone className='mr-2' />WhitePaper</Trans></Link></li>
             
                
                <li></li>
                  
        </ul>
                
              </div>
            </Collapse>
          </li>
          
          </ul> 
        
          
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);


